import styled from "styled-components";

const WrapTicketRow = styled.div`
    display: grid;
    grid-template-columns: 320px minmax(660px, 1fr) 420px 440px;
    width: 100%;
    height: 100%;
	background: ${(props) => (props.index % 2 ? "#C6E9FC" : "#FFFFFF")};    
    .item{
        border-right: 1px solid #005993;
        font-family: 'SVN-Gilroy';
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 1px;
        text-align: center;
        color: #005993;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        &.miss{
            color: #D71249;
        }
    }
    @media screen and (min-width: 1920px) {			
        .item {
            font-size: 38px;
        }
    }
`
export { WrapTicketRow }