import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
//image
import logo_vietinbank from "../../images/logo_vietinbank.svg";
import line from "../../images/mark.svg";

const Bound = styled.div`
	display: flex;
    align-items: center;
    justify-content: space-between;
	height: 152px;
    padding: 24px 40px;
    box-sizing: border-box;	
    background: #7ED3F7;
	.wrap-info {
		display: flex;
        align-items: center;
		gap: 24px;
		.moment {
            width: 212px;
            height: 101px;
            padding: 16px 10px 12px 10px;
            box-sizing: border-box;
            border-radius: 12px;
            background: #FFFFFF;
            text-align: center;
            font-family: 'SVN-Gilroy';
            font-size: 48px;
            font-weight: bold;
            line-height: 76.8px;
            text-align: center;
            color: #D71249;
        }
		.info {			
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
            font-family: 'SVN-Gilroy';
            font-weight: 700;
            line-height: 48px;
            text-align: left;
            /*font-size: ${(props) => (props.active ? "40px" : "28px")};*/
			/*font-weight: ${(props) => (props.active ? "bold" : "500")};*/
            font-size: 40px;
            font-weight: bold;
			color: #005993;			
		}
	}
	.line {
		display: flex;
		width: 100%;
		height: 12px;
		background-image: url(${line});
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
`;

export default class HeaderContainer extends Component {
    state = {
        branch: this.props.branch,
    };
    componentDidMount() {
        this.getTimeNow = setInterval(() => {
            this.setState({ time: moment().format("HH:mm:ss") });
        }, 1000);
    }
    componentWillReceiveProps(n) {
        if (n) {
            this.setState({
                branch: n.branch,
            });
        }
    }
    componentWillUnmount() {
        clearInterval(this.getTimeNow);
    }
    render() {
        return (
            <Bound active={this.state.branch ? true : false}>
                <div className="wrap-info">
                    <div className="moment">
                        {this.state.time}
                    </div>
                    <div className="info">
                        {this.state.branch
                            ? this.state.branch.OfficeName
                            : "Chưa cấu hình ..."}
                    </div>
                </div>
                <div className="logo">
                    <img src={logo_vietinbank} alt="logo" />
                </div>
                {/*<div className="line" />*/}
            </Bound>
        );
    }
}
