import Axios from "axios"
import { host } from '../host'
import * as types from './const'
const apiCurentTicket = host + 'lcd/get-current-ticket-number'

export const currentTicketAction = (OfficeID) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        OfficeID: OfficeID,
    })
    try {
        const res = await Axios.post(apiCurentTicket, queryDataFormat)
        // console.log(res)
        const { data } = res
        if (data) {
            dispatch({
                type: types.GET_CURRENT_TICKET,
                data: data
            })
        }
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: types.DISCONNECT,
            err: 501
        })
        if (err.response && err.response.status === 403) {
            dispatch({
                type: types.LOGOUT_LCD
            })
        }
    }
}

