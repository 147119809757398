import React, { Component } from "react";
import styled from "styled-components";
import ic_close from "../../images/ic_close_white.svg";
import { convertString } from "../../tools";
import { connect } from "react-redux";
import { disconnectLcdAction } from "../../action/connectLcdAction";
const Bound = styled.div`
	padding: 14px 12px 16px 12px;
	width: 240px;
    box-shadow: 0px 4px 24px 0px #00000014;
	background-color: #ffffff;
	font-family: "SVN-Gilroy";
    border-radius: 4px;
	.row_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title {
			font-size: 24px;
			font-weight: bold;
			line-height: 1.08;
			color: #005993;
			display: flex;
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.close {
			cursor: pointer;
		}
	}
	.row_main {
		margin-top: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.show_id {
			font-size: 12px;
			font-weight: 500;
			line-height: 1.3;
			color: #222222;
		}
		.div_btn {
			display: flex;
			justify-content: center;
			align-items: center;

			.btn {
				width: 88px;
				height: 36px;
				border-radius: 18px;
				background-color: #f31919;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
				cursor: pointer;
			}
			.btn-dis {
				width: 88px;
				height: 36px;
				border-radius: 18px;
				background-color: #fb6d6d;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}
`;

class PopupCouterSuccessV2 extends Component {
	onClick = () => {
		let dataBranch = localStorage.getItem("dataBranch");
		this.props.disconnectLcdAction(JSON.parse(dataBranch).LcdID);
		this.props.disconnectBranch("");
	};
	render() {
		const { data } = this.props;
		return (
			<Bound>
				<div className="row_header">
					<div className="title">{this.props.data.OfficeName}</div>
					<img
						className="close"
						onClick={this.props.onClosePopup}
						src={ic_close}
						alt="close"
					/>
				</div>
				<div className="row_main">
					<div className="show_id">
						ID: {convertString(data.LcdID)}
					</div>
					<div />
				</div>
			</Bound>
		);
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		disconnectLcdAction: (ID) => dispatch(disconnectLcdAction(ID)),
	};
};
export default connect(null, mapDispatchToProps)(PopupCouterSuccessV2);
