import Axios from "axios"
import * as types from './const'
import { host } from "../host"

export const getClientName = (clientName) => ({
    type: types.GET_CLIENT_NAME,
    clientName
})

const apiGetClientName = process.env.NODE_ENV !== 'production' ?
	host + 'dns/host/clientName'
	:
	"https://coredns.vietinbank.vn/dns/host/clientName"
export const getNameOfClient = () => async dispatch => {
    Axios.defaults.withCredentials = false;
    delete Axios.defaults.headers.common["CSRF-TOKEN"];

    if (process.env.NODE_ENV === 'development') {
		dispatch(getClientName('cong'))
		return;
	}

    try {
        const resPc = await Axios.get(apiGetClientName);
        console.log("clientName: ", resPc.data)
        if (typeof (resPc.data) !== 'string' || !resPc.data){
            dispatch(getClientName("EMPTY"))
            return;
        }

        dispatch(getClientName(resPc.data))
    }
    catch (err) {
        dispatch(getClientName('vtb1'))
        console.dir(err)
    }
}