import React from 'react'
//css
import { WrapTicketRow } from './index.styles'
//key
import { INPROCESSING, MISSEDCALL, MOVING, PAUSED, UPDATED, WAITING } from '../../../data/key'

const TicketRowV3 = ({ data, index }) => {
    return (
        <WrapTicketRow index={index}>
            <div className={data?.Status === MISSEDCALL ? 'item miss' : 'item'}>
                {data?.TicketNumber}
            </div>
            <div className={data?.Status === MISSEDCALL ? 'item miss' : 'item'}>
                {data?.Fullname}
            </div>
            <div className={data?.Status === MISSEDCALL ? 'item miss' : 'item'}>
                {data?.SegmentDesc}
            </div>
            <div className={data?.Status === MISSEDCALL ? 'item miss' : 'item'}>
                {
                    data?.Status === WAITING ?
                        <p>Chờ giao dịch</p>
                        : data?.Status === MISSEDCALL || data?.Status === PAUSED || data?.Status === INPROCESSING || data?.Status === MOVING || data?.Status === UPDATED ?
                            <p>{`Quầy ${data?.CounterNumber}`}</p>
                            : null
                }
            </div>

        </WrapTicketRow>
    )
}

export default TicketRowV3